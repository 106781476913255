export interface SystemState {
  loggedIn: boolean;
  userName: string;
  currentPage: string;
  uid?: string;
  idNumber: number;
  companyName: string;
  registrarCompaniesId?: string;
  businessId: number;
}

export const UPDATE_SESSION = "UPDATE_SESSION";

interface UpdateSessionAction {
  type: typeof UPDATE_SESSION;
  payload: SystemState;
}

export type SystemActionTypes = UpdateSessionAction;
