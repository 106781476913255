import { SystemState, SystemActionTypes, UPDATE_SESSION } from "./types";
import { navigate } from "gatsby";
let userDetails: SystemState | undefined;
try {
  userDetails = {
    ...JSON.parse(localStorage.userDetails),
    ...JSON.parse(localStorage.userDetails),
  };
  console.log("lior ", userDetails);
} catch (error) {}
const initialState: SystemState = userDetails
  ? userDetails
  : {
      loggedIn: false,
      userName: "",
      currentPage: "/",
      uid: "",
      idNumber: 0,
      companyName: "",
      registrarCompaniesId: "",
      businessId: 0,
    };
export function systemReducer(state = initialState, action: SystemActionTypes): SystemState {
  switch (action.type) {
    case UPDATE_SESSION: {
      navigate(action.payload.currentPage);
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
