import React, { Component } from "react";
import { Provider } from "react-redux";
import { createStore as reduxCreateStore } from "redux";
import { rootReducer } from "./store/index";

const createStore = () => reduxCreateStore(rootReducer);

export default ({ element }: any) => (
  <div>
    <Provider store={createStore()} key={Math.random()}>
      <div>{element}</div>
    </Provider>
  </div>
);
